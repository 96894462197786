import React, { useState, useEffect, useRef } from "react";
import letterImg from "/src/assets/img/from_anywhere_1.png";
import letterImg1 from "/src/assets/img/thousands_times.png";
import letterImg2 from "/src/assets/img/from_anywhere.png";
import letterImg3 from "/src/assets/img/print_services.png";

const Slider = () => {
  const slides = [
    {
      title: "Mail Letters Online: From Anywhere in the Country",
      description: `With our intuitive platform and user-friendly interface, you can easily mail a letter online or integrate our application seamlessly with your existing systems through our print and mail API. Submit your letter requests by our stated cutoff time, and we will print and mail them by the following business day, ensuring timely induction into the USPS mail stream. <br />  Our service is ideal for all industries requiring USPS mail, including, but not limited to: <br /><br />
      <div style="display: flex, gap: 10px, flex-wrap: wrap">
        <div class="tags">Law Firms</div>
        <div class="tags">Property Management Companies</div>
        <div class="tags">Association Management Companies</div>
        <div class="tags">Self-Storage Companies</div>
        <div class="tags">Financial Institutions</div>
        <div class="tags">Insurance Companies</div>
        <div class="tags">Land Service Companies</div>
        <div class="tags">State and Local Government</div>
      </div>`,
      img: letterImg,
    },
    {
      title:
        "Certified Mail Service: No More Labels or Trips to the Post Office",
      description: `Certified Mail is usually a time-consuming process involving manual forms, labels, post office visits, and tracking on the USPS website. Our service streamlines this: simply upload your document, and we handle the rest. <br /> <br />  While delivery depends on USPS, we ensure your letter is prepared and inducted, with all USPS scans available on our platform. You also get an image of the mailed letter and the Return Receipt Electronic (RRE) signature (if applicable), creating a fully digital experience. Send Certified Mail from home or the office in just a few clicks.`,
      img: letterImg2,
    },
    {
      title: "Comprehensive Letter Shop Services: With a Modern Take",
      description: `
Unlike most letter shops that require large print runs due to system and equipment constraints, our infrastructure supports both short and long print cycles with various print and mailing requirements, including: <br /> <br /> 
      <div style="display: flex, gap: 10px, flex-wrap: wrap">
        <div class="tags">Certified Mail, Priority Mail, or First-Class Mail</div>
        <div class="tags">#10 or 9x12 envelopes</div>
        <div class="tags">Priority Mail Envelopes, Priority Mail Boxes</div>
        <div class="tags">Color or Black & White Print</div>
        <div class="tags">Custom Inserts</div>
        <div class="tags">Single-Sided Print or Double-Sided Print</div>
        <div class="tags">Affidavits of Mailing</div>
      </div>
      `,
      img: letterImg3,
    },
    {
      title: "Bulk and Mass Mailings: Send One Letter or Thousands at a Time",
      description: `We serve clients with varying mailing needs, from one-time large mass mailings to daily mailings of different sizes. For clients sending to multiple recipients, we offer the ability to extract addresses directly from their PDFs, allowing them to submit thousands of addresses for mailings in seconds. <br /> <br />  We also offer an Import from File option, where users can map an Excel or CSV file to the documents they want to send. Alternatively, users can leverage our print and mail API to send their mail directly from their existing platforms. We also offer competitive custom pricing for businesses sending large amounts of mail.`,
      img: letterImg1,
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");
  const lastScrollTop = useRef(0);
  const cardRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute("data-index"));
          setActiveIndex(index);
        }
      });
    }, observerOptions);

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

 
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop.current) {
        setScrollDirection("down"); 
      } else {
        setScrollDirection("up"); 
      }
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop; 
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getBackgroundColor = (index) => {
    const baseColor = [170, 206, 251]; 

    const adjustColor = (color, percentage) => {
      return color.map((c) => Math.min(255, Math.floor(c + (255 - c) * percentage)));
    };
  
    if (scrollDirection === "up" || index >= activeIndex) {
      return `rgb(${baseColor.join(",")})`;
    }

    let percentage = 0; 

    if (index === activeIndex - 1) {
      percentage = 0.4; 
    } else if (index === activeIndex - 2) {
      percentage = 0.6; 
    } else if (index === activeIndex - 3) {
      percentage = 0.8;
    }

    const color = adjustColor(baseColor, percentage);

    return `rgb(${color.join(",")})`;
  };

  return (
    <div className="slider-container">
      <ul className="cards">
        {slides.map((slide, index) => (
          <li className="card" key={index} data-index={index} ref={(el) => (cardRefs.current[index] = el)}>
            <div
              className="card-content"
              style={{
                backgroundColor: getBackgroundColor(index), 
                transition: "background-color 0.5s ease", 
              }}
            >
              <div className="card-content-text">
                <h2>{slide.title}</h2>
                <p
                  className="carouselSlideWrapperDescr"
                  dangerouslySetInnerHTML={{
                    __html: slide.description,
                  }}
                ></p>
              </div>
              <figure>
                <img src={slide.img} alt={slide.title} />
              </figure>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slider;
