import React, { useState } from "react"
import icon1 from "/src/assets/img/howToGetIcon1.svg"
import icon2 from "/src/assets/img/howToGetIcon2.svg"
import icon3 from "/src/assets/img/howToGetIcon3.svg"
import icon4 from "/src/assets/img/howToGetIcon4.svg"

const HowGetStarted = () => {
  return (
    <>
      <div className="introHowGetStartedSlideWrapper">
        <div className="introSlideHowToGet">
          <div className="slideContent">
            <div className="mainContainer gutter120">
              <div className="textPrimary titlePrice mb30">How to Get Started</div>
              <p className="getStartedTitleDesription">
                Getting started with UploadLetters.com is simple:
              </p>
              <div className="howToGetCont">
              <div className="howToGetContBG"></div>
                <div className="howToGetBlock howToGetFirst">
                  <img src={icon1} className="howToGetIcon" />
                  <div className="howToGetSubTitle">Sign Up</div>
                  <div className="howToGetMargin"></div>
                  <div className="howToGetDescr">
                    Create an account on our <br /> platform.
                  </div>
                </div>
                <div className="howToGetBlock">
                  <img src={icon2} className="howToGetIcon" />
                  <div className="howToGetSubTitle">Upload Your <br /> Documents</div>
                  <div className="howToGetDescr">
                  Easily upload your letters <br /> and documents.
                  </div>
                </div>
                <div className="howToGetBlock">
                  <img src={icon3} className="howToGetIcon" />
                  <div className="howToGetSubTitle">Choose Your <br /> Mailing Options</div>
                  <div className="howToGetDescr">
                  Select the mailing options <br /> that fit your needs.
                  </div>
                </div>
                <div className="howToGetBlock">
                  <img src={icon4} className="howToGetIcon" />
                  <div className="howToGetSubTitle">Place Your <br /> Order</div>
                  <div className="howToGetDescr">
                  We take care of the rest, from <br /> printing to mailing.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowGetStarted
