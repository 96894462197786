import React,{ useState, useLayoutEffect } from 'react'
import {StaticQuery, Link, graphql} from "gatsby";
import Seo from '../seo';
import { Helmet } from 'react-helmet';


const IntroComponent = (props) => {
  const [visible, setVisible] = useState(false)
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  const data = props.data;
  const contactUs = props.data.allDataJson.edges[0].node.header.contactUs
  const texts = data.allTexts.edges[0].node;

  useLayoutEffect(()=> {
      setVisible(true)
  },[])
  return (
    <>
    {/* <Seo title="UploadLetters.com - Mail Letters Online" description='Mail letters online. Send Certified Mail, First-Class Mail, and Priority Mail instantly. We handle the printing and mailing process. Guaranteed next-day service.' /> */}
    <div className="introSlideWrapper">
      <div className="introSlide">
        <div className="slideContent">
          <div className="mainContainer gutter120">
            <div style={{ overflow: 'hidden' }}>
              <h1 className={"IntoSlideTitleTranslateVisible"}>{t(data.allDataJson.edges[0].node.indexIntro.title, texts).split(' ')
                .map((e, i) => {
                  return (<span style={{ overflow: 'hidden' }}>
                    <span className={visible ? "TitleWordVisible" : "TitleWordTranslate"} style={{ transitionDelay: `0.${i}s` }}>{e.split('').map((e) => {
                      if (e === '®') {
                        return <span className="r">®</span>;
                      } else {
                        return e;
                      }
                    })}</span>
                    <span> </span>
                    {(i + 1) % 3 === 0 && i !== 0 && i !== 6 && i !== 5 && i !== 8 ? <br /> : null}
                  </span>);
                })}</h1>
            </div>
            <div className="btnHolder getStartedWrapper" style={visible ? {} : { overflow: 'hidden' }}>
              <a href='https://app.uploadletters.com/register' className={visible ? "button intoSlideButton" : "button intoSlideButtonNonVisible"}>
                <div className="intoSlideButtonText">Get Started</div>
              </a>
              <Link to="/contact-us/" className={visible ? "contactUsLink" : "contactUsLinkNonVisible"}>{t(contactUs, texts)}</Link>
            </div>
          </div>
        </div>
      </div>
    </div></>
  )
}

const IntroQuery = (props) => (
  <StaticQuery
    query={graphql`
      query TextsQuery {
          allDataJson {
            edges {
              node {
                header {
                  contactUs
                }
                indexIntro {
                  title
                  button
                }
              }
            }
          },
          allTexts {
            edges {
              node {
                indexIntro_title
                indexIntro_button
                header_contactUs
              }
            }
          }
        }
    `}
    render={(data) => <IntroComponent data={data} {...props}/>}
  />
)

export default IntroQuery